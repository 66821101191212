<!-- 商品app用户管理页面 -->
<template>
  <div class="storeUserManagement">
    <div class="head-area clr">
      <div class="fl clr left-search">
        <div class="fl search-item">
          <div class="fl head-label-area">支付宝对公账号</div>
          <Input
            class="fl"
            v-model="alipayAccount"
            placeholder="支付宝对公账号"
            clearable
            style="width:200px"
          ></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area" style="width:150px">支付宝对公账号名称</div>
          <Input
            class="fl"
            v-model="alipayAccountName"
            placeholder="支付宝对公账号名称"
            clearable
            style="width:200px"
          ></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">手机号</div>
          <Input class="fl" v-model="mobile" placeholder="手机号" clearable style="width:200px"></Input>
        </div>
      </div>
      <div class="fr right-search-btn">
        <Button type="primary" style="margin:0 0 10px;" icon="ios-search" @click="search">查询</Button>
        <Button @click="reset" icon="ios-refresh">重置</Button>
      </div>
    </div>
    <Button type="primary" @click="formData.mobile = '';modelShow = true">用户注册</Button>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight"></Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        show-total
        :page-size="size"
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <Modal v-model="modelShow" title="商家app用户注册" width="500">
      <div v-if="modelShow">
        <Form ref="formInline" :model="formData" :rules="ruleInline" :label-width="100">
          <FormItem label="手机号：" prop="mobile">
            <Input v-model="formData.mobile" placeholder="请输入手机号" clearable></Input>
          </FormItem>
          <FormItem label="商家名称：" prop="nickname">
            <Input v-model="formData.nickname" placeholder="请输入商家名称" clearable></Input>
          </FormItem>
        </Form>
      </div>
      <div class="demo-drawer-footer">
        <Button style="margin-right: 8px" @click="modelShow = false">取消</Button>
        <Button type="primary" @click="registerUser">注册</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { getLaikeList, registerUser } from '@/api/storeUserManagement.js'

export default {
  data() {
    return {
      tableHeight: window.innerHeight - 330,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '用户id',
          key: 'id'
        },
        {
          title: '商家名称',
          key: 'storeName'
        },
        {
          title: '支付宝对公账号',
          key: 'alipayAccount'
        },
        {
          title: '支付宝对公账号名称',
          key: 'alipayAccountName'
        },
        {
          title: '手机号',
          key: 'mobile'
        },
        {
          title: '商户id',
          key: 'storeId'
        },
        {
          title: '创建时间',
          key: 'createTime'
        }
      ],
      dataList: [
      ],
      alipayAccount: '',
      alipayAccountName: '',
      mobile: '',
      modelShow: false,
      formData: {
        mobile: '',
        nickname: ''
      },
      ruleInline: {
        mobile: [
          { required: true, message: '请输入手机号！', trigger: 'blur' },
        ],
        nickname: [
          { required: true, message: '请输入商家名称！', trigger: 'blur' },
        ]
      }
    }
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    reset() {
      this.current = 1
      this.alipayAccount = ''
      this.alipayAccountName = ''
      this.mobile = ''
      this.findPageDataList()
    },
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      let params = { size: this.size, pageIndex: this.current }
      params.alipayAccount = this.alipayAccount
      params.alipayAccountName = this.alipayAccountName
      params.mobile = this.mobile
      getLaikeList(params).then(res => {
        if (res.success) {
          this.dataList = res.result.list
          this.total = res.result.total
        }
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    },
    registerUser() {
      this.$refs.formInline.validate((valid) => {
        if (valid) {
          registerUser(this.formData).then(res => {
            if (res.success) {
              this.modelShow = false
              this.$Message.success('注册成功！')
              this.findPageDataList()
            }
          })
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.storeUserManagement {
  .left-search {
    max-width: 1000px;
    width: calc(100% - 100px);
    .search-item {
      margin-bottom: 10px;
    }
  }
  .right-search-btn {
    width: 100px;
    border-left: 1px solid #e5e6eb;
    padding-left: 20px;
  }
  .head-label-area {
    line-height: 36px;
    // width: 120px;
    margin-left: 10px;
    padding-right: 15px;
    text-align: right;
  }
}
</style>